import React, { useState, useEffect } from 'react'
import './Modals.css'
import close from '../../Assets/Icons/close.png'
import { toast } from 'react-toastify'
import axios from 'axios'

export default function NewTradeModal({setOpen, getRequests, data, setData}) {

  const [venueName, setVenueName] = useState("")
  const [contactName, setContactName] = useState("")
  const [email, setEmail] = useState("")
  const [lineOne, setLineOne] = useState("")
  const [lineTwo, setLineTwo] = useState("")
  const [town, setTown] = useState("")
  const [county, setCounty] = useState("")
  const [postcode, setPostcode] = useState("")

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log(data)
    if(!data){
        return
    } else {
        setVenueName(data.venueName);
        setContactName(data.name);
        setEmail(data.email);
        setLineOne(data.address)
    }

  }, [data])

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const handleSubmit = () => {
   
  }


  return (
    <div className='modal' onClick={() => { setOpen(false); setData(null)}}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='close-modal-btn hover' src={close} alt='Close Thiago Modal' onClick={() => { setOpen(false); setData(null)}}/>

          <h3 className='modal-header' style={{color: '#313131', fontFamily: 'Futura-pt', textTransform: 'uppercase', fontSize: 16, letterSpacing: 2}}>New Trade Account</h3>

            <p className='modal-label'>Venue Name</p>
          <input className='single-input' placeholder='Venue name' type='text' value={venueName} onChange={(e) => setVenueName(e.target.value)}/>
          
          <p className='modal-label'>Primary Contact</p>
          <input className='top-input' placeholder='Contact Name' type='text' value={contactName} onChange={(e) => setContactName(e.target.value)}/>
          <input className='bottom-input' placeholder='Contact Email' type='text' value={email} onChange={(e) => setEmail(e.target.value)}/>

          <p className='modal-label'>Delivery Address</p>
          <input className='top-input' placeholder='Line 1' type='text' value={lineOne} onChange={(e) => setLineOne(e.target.value)}/>
          <input className='middle-input' placeholder='Line 2' type='text' value={lineTwo} onChange={(e) => setLineTwo(e.target.value)}/>
          <input className='middle-input' placeholder='Town' type='text' value={town} onChange={(e) => setTown(e.target.value)}/>
          <input className='middle-input' placeholder='County' type='text' value={county} onChange={(e) => setCounty(e.target.value)}/>
          <input className='bottom-input' placeholder='Postcode' type='text' value={postcode} onChange={(e) => setPostcode(e.target.value)}/>


          <button className='modal-btn'  disabled={loading} onClick={handleSubmit}>
            {loading ? 'Creating...' : "Create"}
          </button>
        </div>

    </div>
  )
}
