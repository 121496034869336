import React, { useState } from 'react'
import moment from 'moment'
import './CustomerService.css'
import Loader from './Loader'
import { useCustomerService } from '../Context/CustomerServiceContext';
import CustomerServiceModal from '../Components/Modals/CustomerServiceModal'

export default function CustomerService() {

  const { enquiries, loading, getEnquiries } = useCustomerService();
  const [open, setOpen] = useState(false)
  const [requestId, setRequestId] = useState(null)

  const handleOpen = (enquiry) => {
    setRequestId(enquiry._id);
    setOpen(true)
  }

  return (
    <div className='business-outlet'>
      <h1>Customer Service Enquiries</h1>

      {loading ?
        <Loader />
        :
        enquiries.length < 1 ?
        <p>No outstanding customer enquiries</p>
        :
        <table id='customer-table'>
          <thead>
          <tr>
            <th className='customer-table-cell'>Date Raised</th>
            <th className='customer-table-cell'>Name</th>
            <th className='customer-table-cell'>Email</th>
            <th className='customer-table-cell'>Subject</th>
          </tr>
          </thead>
          <tbody>
          {enquiries.map((enquiry, i) => {
            return (
              <tr key={i} onClick={() => handleOpen(enquiry)}>
                <td className='customer-table-cell'>{moment(enquiry.createdAt).format("DD/MM/YYYY")} ({moment(enquiry.createdAt).fromNow()})</td>
                <td className='customer-table-cell'>{enquiry.name}</td>
                <td className='customer-table-cell'>{enquiry.email}</td>
                <td className='customer-table-cell'>{enquiry.subject}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
  
      }

      {open && <CustomerServiceModal setOpen={setOpen} requestId={requestId} getEnquiries={getEnquiries}/> }

    </div>
  )
}
