import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

// Create the context
const CustomerServiceContext = createContext();

// Create a provider component
export const CustomerServiceProvider = ({ children }) => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEnquiries();
  }, []);

  const getEnquiries = () => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/thiago/get-active-customer-enquiries`)
      .then((res) => {
        setEnquiries(res.data);
      })
      .catch(() => {
        toast.error("Error retrieving enquiries");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomerServiceContext.Provider value={{ enquiries, loading, getEnquiries }}>
      {children}
    </CustomerServiceContext.Provider>
  );
};

// Custom hook to use the customer service context
export const useCustomerService = () => {
  return useContext(CustomerServiceContext);
};