import React, { useState, useEffect } from 'react'
import close from '../../Assets/Icons/close.png'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext'
import moment from 'moment'

export default function CustomerServiceModal({setOpen, requestId, getEnquiries}) {

  let { currentUser } = useAuth()

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [msg, setMsg] = useState("")

  const [loading, setLoading] = useState(false)
  const [adding, setAdding] = useState(false)
  const [note, setNote] = useState("")
  const [notes, setNotes] = useState([])

  useEffect(() => {
    if(requestId){
      getRequestInfo()
    }
  }, [requestId])

  const getRequestInfo = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/thiago/get-customer-service-request`, {requestId})
    .then((res) => {
      console.log(res.data)
      let { name, email, subject, msg, notes } = res.data;
      setName(name);
      setEmail(email);
      setSubject(subject);
      setMsg(msg);
      setNotes(notes)
    })
    .catch(() => {
      console.log("Error")
    })
  }

  const addNote = () => {
    if(!note){
      return
    } else {
      setAdding(true)
      let newNotes = [...notes, {
        createdBy: currentUser.displayName,
        createdAt: new Date(),
        note
      } ] 

      axios.post(`${process.env.REACT_APP_API_URL}/thiago/add-note`, {requestId, notes: newNotes})
      .then(() => {
        getRequestInfo()
        setNote("")
        toast.success("Added")
      })
      .catch(() => {
        console.log("Error")
      })
      .finally(() =>{
        setAdding(false)
      })
    }
    
  }

  const resolve = () => {
   
      setLoading(true)
      let newNotes = [...notes, {
        createdBy: currentUser.displayName,
        createdAt: new Date(),
        note: "Resolved"
      } ] 

      axios.post(`${process.env.REACT_APP_API_URL}/thiago/resolve-request`, {requestId, notes: newNotes})
      .then(() => {
        setOpen(false)
        toast.success("Resolved")
        getEnquiries()
      })
      .catch(() => {
        console.log("Error")
      })
      .finally(() =>{
        setLoading(false)
      })
    
    
  }

  return (
    <div className='modal' onClick={() =>  setOpen(false)}>
        <div className='modal-container' onClick={handleContainerClick}>
          <img className='close-modal-btn hover' src={close} alt='Close Thiago Modal' onClick={() => setOpen(false)}/>

          <h3 className='modal-header' style={{color: '#313131', fontFamily: 'Futura-pt', textTransform: 'uppercase', fontSize: 16, letterSpacing: 2}}>Customer service request</h3>

          <input disabled className='top-input' placeholder='Full name' type='text' value={name} />
          <input disabled className='bottom-input' placeholder='Email address' type='text' value={email} />

          <p className='modal-label'>Subject</p>
          <input disabled className='single-input' placeholder='Subject' type='text' value={subject} />

          <p className='modal-label'>Message</p>
          <textarea disabled className='modal-textarea' placeholder='New note' type='text' value={msg}/>

          <div className='divider'/>

          <p className='modal-label'>Notes</p>

          {notes.length < 1 ?
          <p>No notes added</p>
          :
          notes.map((item, i) => {
            return (
              <div key={i} style={{fontFamily:'Futura-pt'}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <p style={{fontSize: 14, color:'grey'}}>{moment(item.createdAt).format("DD/MM/YY hh:mma")}</p>
                  <p style={{fontSize: 14, color:'grey'}}>{item.createdBy}</p>
                </div>
                <p style={{marginTop: 0}}>{item.note}</p>

                <div className='divider'/>
              </div>
            )
          })
          }

          <textarea className='modal-textarea' placeholder='Add new note' type='text' value={note} onChange={(e) => setNote(e.target.value)}/>

          <button className='inverted-modal-btn'  disabled={adding} onClick={addNote}>
            {adding ? 'Adding...' : "Add note"}
          </button>

          <button className='modal-btn'  disabled={loading} onClick={resolve}>
            {loading ? 'Resolving...' : "Mark as resolved"}
          </button>
        </div>

    </div>
  )
}
