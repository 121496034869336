import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Context
import { AuthProvider } from './Context/AuthContext'
import { CustomerServiceProvider } from './Context/CustomerServiceContext';
import { TradeRequestProvider } from './Context/TradeRequestContext';
//Components
import RequireAdminAuth from './Components/RequireAdminAuth';
import AdminUI from './Components/AdminUI';
import Login from './Components/Login';
import ScrollToTop from './Components/ScrollToTop';
import Dashboard from './Components/Dashboard';
import Staff from './Components/Staff';
import CustomerService from './Components/CustomerService';
import TradeAccounts from './Components/TradeAccounts';

function App() {
  return (
    <AuthProvider>
      <CustomerServiceProvider>
        <TradeRequestProvider>
          <ToastContainer position='top-center' />

          <BrowserRouter>

            <ScrollToTop />
            
            <Routes>

              {/*Public routes */}
              <Route path='/login' element={<Login />} />

              {/*Admin routes*/}
              <Route element={<RequireAdminAuth />} >
                <Route path='/' element={<AdminUI />}>
                  <Route index element={<Dashboard />} />
                  <Route path='staff' element={<Staff />} />
                  <Route path='customer-service' element={<CustomerService />} />
                  <Route path='trade' element={<TradeAccounts />} />
                </Route>
              </Route>

            </Routes>
          </BrowserRouter>
        </TradeRequestProvider>
    </CustomerServiceProvider>
  </AuthProvider>
  );
}

export default App;
