import React, { useState, useEffect } from 'react'
import AddStaff from './Modals/AddStaff'
import axios from 'axios'
import { toast } from 'react-toastify'
import user from '../Assets/Icons/user.png'
import './Staff.css'
import Loader from './Loader'

export default function Staff() {

  const [open, setOpen] = useState(false)
  const [staffList, setStaffList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getStaff()
  }, [])

  const getStaff = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_URL}/thiago/get-staff`)
    .then((res) => {
      setStaffList(res.data)
    })
    .catch((e) => {
      toast.error("Error retreiving staff")
    })
    .finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className='business-outlet'>
      <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap:'wrap'}}>
        <h1>Thiago Staff</h1>
        <button className='primary-btn hover' onClick={() => setOpen(!open)}>Add new staff</button>
      </div>
      
        {open && <AddStaff setOpen={setOpen} getStaff={getStaff} />}

        {loading ?
          <Loader />
          :
          <table id='staff-table'>
            <tr>
              <th></th>
              <th className='staff-table-cell'>Name</th>
              <th className='staff-table-cell'>Email</th>
            </tr>

            {staffList.map((staffMember , i) => {
              return (
                <tr key={i}>
                  <td><img style={{height: 35}} src={user} alt='Thiago Tequila staff member'/></td>
                  <td className='staff-table-cell'>{staffMember.name}</td>
                  <td className='staff-table-cell'>{staffMember.email}</td>
                </tr>
              )
            })}
          </table>
        }

       
    </div>
  )
}
