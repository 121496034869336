import React from 'react';
import './Badge.css'; 

const Badge = ({ count }) => {
  return (
    count > 0 && (
      <div className="badge">
        {count}
      </div>
    )
  );
};


export default Badge;