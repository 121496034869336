import React, { useState , useEffect } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import { toast } from 'react-toastify'
import { auth } from '../Firebase'
import logo from '../Assets/logo.png'
import { useAuth } from '../Context/AuthContext';

export default function Login() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  let navigate = useNavigate()

  let { currentUser, admin } = useAuth()

  useEffect(() => {
    if(currentUser && admin){
      navigate('/')
    } else{
      return
    }
  }, [admin, currentUser])

  const handleLogin = () => {
    if (!email || !password) {
      toast.error("Please input all fields")
    } else {
      setLoading(true)
      signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Signed in
       console.log("Signed in")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }

  const resetPassword = () => {
    if (!email) {
      toast.error("Please input email")
    } else {
      sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent!")
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }
  }


  return (
    <div id='login-page'>
      <div id='credentials-container'>
        <img id='login-logo' src={logo}/>
        <input className='login-input' placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        <input className='login-input' type='password' placeholder='Password' value={password} onChange={(e)=>setPassword(e.target.value)}/>
        <button id='login-btn' onClick={handleLogin} disabled={loading}>{loading? "Logging in..." : "Login"}</button>

        <p id='forgotten' onClick={resetPassword}>Forgotten Password?</p>
      </div>
    </div>
  )
}
