import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

// Create the context
const TradeRequestContext = createContext();

// Create a provider component
export const TradeRequestProvider = ({ children }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRequests();
  }, []);

  const getRequests = () => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/thiago/get-trade-requests`)
      .then((res) => {
        setRequests(res.data);
      })
      .catch(() => {
        toast.error("Error retrieving requests");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <TradeRequestContext.Provider value={{ requests, loading, getRequests }}>
      {children}
    </TradeRequestContext.Provider>
  );
};

// Custom hook to use the customer service context
export const useTradeRequest = () => {
  return useContext(TradeRequestContext);
};