import React, { useState, useEffect} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import './AdminUI.css'
import {  signOut } from "firebase/auth";
import { auth } from '../Firebase';
import logo from '../Assets/logo.png'
import { useCustomerService } from '../Context/CustomerServiceContext';
import Badge from './Badge';
import { useTradeRequest } from '../Context/TradeRequestContext';

export default function AdminUI() {
  let location = useLocation();

  const [navOpen, setNavOpen] = useState(window.screen.width > 750 ? true : false)
  const [active, setActive] = useState('Dashboard')

  const { enquiries } = useCustomerService();
  const { requests } = useTradeRequest();

  useEffect(() => {
    let url = location.pathname

    if(url === '/'){
      setActive("Dashboard")
    } else if(url.startsWith('/staff')){
      setActive("Staff")
    } else if(url.startsWith('/customer-service')){
      setActive("Customer")
    } else if(url.startsWith('/trade')){
      setActive("Trade")
    } else {
      setActive("")
    }

  }, [location])

    const handleLogout = () =>{
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }



  return (
    <div>

      <div id='burger' className={`burger ${navOpen ? 'open' : ''}`} onClick={() => setNavOpen(!navOpen)}>
        <span className='bar'></span>
        <span className='bar'></span>
        <span className='bar'></span>
      </div>



        <header className={navOpen ? 'business-nav' : 'business-nav-closed'}>

          <div >

            <Link to={'/'}>
              <img src={logo} style={{width: '100%', marginBottom: 30}}/>
            </Link>

            <nav>
              <p style={{fontFamily: 'Futura-pt', fontSize: 11, color:'#111111', opacity: 0.5}}>MENU</p>
                <Link to={'/'} className={active === 'Dashboard' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Dashboard' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../Assets/Icons/home.png')} alt='Home icon'/>
                  <p className={active === 'Dashboard' ? 'business-link-txt-active' : 'business-link-txt'}>Dashboard</p>
                </Link>
               
                <Link to={'staff'} className={active === 'Staff' ? 'business-nav-item-active' : 'business-nav-item'}onClick={() => window.screen.width < 750 ? setNavOpen(false) : null} >
                  <img className={active === 'Staff' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../Assets/Icons/applicants.png')} alt='Acts icon'/>
                  <p className={active === 'Staff' ? 'business-link-txt-active' : 'business-link-txt'}>Staff</p>
                </Link>

                <Link 
                  to={'trade'} 
                  className={active === 'Trade' ? 'business-nav-item-active' : 'business-nav-item'}
                  onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}
                  style={{ position: 'relative' }}  // Added to ensure the badge is positioned correctly
                >
                  <img 
                    className={active === 'Trade' ? 'nav-icon icon-filter' : 'nav-icon'} 
                    src={require('../Assets/Icons/venue.png')} 
                    alt='Trade icon'
                  />

                  <p className={active === 'Trade' ? 'business-link-txt-active' : 'business-link-txt'}>
                    Trade Accounts
                  </p>
                  <Badge count={requests.length} />

                </Link>

              
                <Link 
                  to={'customer-service'} 
                  className={active === 'Customer' ? 'business-nav-item-active' : 'business-nav-item'}
                  onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}
                  style={{ position: 'relative' }}  // Added to ensure the badge is positioned correctly
                >
                  <img 
                    className={active === 'Customer' ? 'nav-icon icon-filter' : 'nav-icon'} 
                    src={require('../Assets/Icons/customer-service.png')} 
                    alt='Customer service icon'
                  />
                  
                  <p className={active === 'Customer' ? 'business-link-txt-active' : 'business-link-txt'}>
                    Customer Service
                  </p>
                  <Badge count={enquiries.length} />

                </Link>


            </nav>
            </div>

            <button className='business-nav-item' id='business-logout-btn' onClick={handleLogout}>
                  <img className='nav-icon' src={require('../Assets/Icons/logout.png')} alt='Logout icon'/>
                  <p className='business-link-txt'>Logout</p>
                </button>

        </header>

        {/*
        
        !verified && 
       <div id='blur-overlay'>

          <div className='blur-info-container'>

            <div style={{width: '100%', height: 300, backgroundColor: '#f1f1f1', borderRadius: 12, marginBottom: 20}}>

            </div>

            <h2 className='om-header' style={{fontSize: 32}}>We just sent you a verification email</h2>

            <p className='om-subheader' style={{fontSize: 18}}>Hi there, we just sent you a secure verification link. Kindly check your inbox and spam. </p>

            <button className='primary-btn' style={{fontSize: 18, width: 150}} onClick={sendVerification}>Resend link</button>

          </div>

        </div>*/
}

        <Outlet context={{ active, setActive }}/>
    </div>
  )
}
