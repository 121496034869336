import React, { useState } from 'react'
import moment from 'moment'
import './TradeAccount.css'
import Loader from './Loader'
import { useTradeRequest } from '../Context/TradeRequestContext';
import send from '../Assets/Icons/send.png'
import convert from '../Assets/Icons/convert.png'
import NewTradeModal from './Modals/NewTradeModal';

export default function TradeAccounts() {

  const { requests, loading, getRequests } = useTradeRequest();
  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState(1)
  const [data, setData] = useState(null)


  const handleOpen = (request) => {
    setData(request);
    setOpen(true)
  }

  const handleLWC = () => {
    if(window.confirm("Are you sure you want to send to LWC?")){
      console.log("send")
    }
  }

  return (
    <div className='business-outlet'>

      {open && <NewTradeModal data={data} setData={setData} setOpen={setOpen} getRequests={getRequests}/>}
      <h1>Trade Accounts</h1>

      <div id='trade-tab-container'>
        <div className={tab ===1 ? 'trade-tab-active' :'trade-tab'} onClick={() => setTab(1)}>
          <p>Account Requests</p>
          <div className={tab ===1 ? 'tab-underline' : ''}/>
        </div>
        <div className={tab ===2 ? 'trade-tab-active' :'trade-tab'} onClick={() => setTab(2)}>
          <p>Active Accounts</p>
          <div className={tab ===2 ? 'tab-underline' : ''}/>
        </div>
        <div className={tab ===3 ? 'trade-tab-active' :'trade-tab'} onClick={() => setTab(3)}>
          <p>Archived Accounts</p>
          <div className={tab ===3 ? 'tab-underline' : ''}/>
        </div>
      </div>

      {tab === 1 &&
      (loading ?
        <Loader />
        :
        requests.length < 1 ?
        <p>No outstanding trade requests</p>
        :
        <table id='trade-table'>
          <thead>
          <tr>
            <th >Action</th>
            <th className='trade-table-cell'>Date Raised</th>
            <th className='trade-table-cell'>Account Type</th>
            <th className='trade-table-cell'>Contact Name</th>
            <th className='trade-table-cell'>Contact Email</th>
            <th className='trade-table-cell'>Venue Name</th>
            <th className='trade-table-cell'>Venue Address</th>
          </tr>
          </thead>
          <tbody>
          {requests.map((request, i) => {
            return (
              <tr key={i}>
                <td 
                  style={{textAlign: 'center'}}
                  onClick={() => request.choice === 'LWC' ? handleLWC() : handleOpen(request) }
                >
                  <img style={{height: 25}} src={request.choice === 'LWC' ? send : convert} />
                </td>
                <td className='trade-table-cell'>{moment(request.createdAt).format("DD/MM/YYYY")} ({moment(request.createdAt).fromNow()})</td>
                <td className='trade-table-cell'>{request.choice}</td>
                <td className='trade-table-cell'>{request.name}</td>
                <td className='trade-table-cell'>{request.email}</td>
                <td className='trade-table-cell'>{request.venueName}</td>
                <td className='trade-table-cell'>{request.address}</td>
              </tr>
            )
          })
      
      }

      
          </tbody>
        </table>
      )
  
      }

      {/*open && <tradeServiceModal setOpen={setOpen} requestId={requestId} getEnquiries={getEnquiries}/>*/ }

    </div>
  )
}
