import React, { useState } from 'react'
import './Modals.css'
import close from '../../Assets/Icons/close.png'
import { toast } from 'react-toastify'
import axios from 'axios'

export default function AddStaff({setOpen, getStaff}) {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const handleSubmit = () => {
    if(!name || !email){
        toast.error("Please add all fields")
    } else {
        setLoading(true)
        let payload = {name, email}
        console.log(payload)

        axios.post(`${process.env.REACT_APP_API_URL}/thiago/create-staff`, payload)
        .then(() => {
            setName("");
            setEmail("")
            setOpen(false)
            toast.success("Created successfully")
            getStaff()
        })
        .catch(() => {
            toast.error("Error creating staff")
        })
        .finally(() => {
            setLoading(false)
        })
    }
  }


  return (
    <div className='modal' onClick={() =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='close-modal-btn hover' src={close} alt='Close Thiago Modal' onClick={() => setOpen(false)}/>

          <h3 className='modal-header' style={{color: '#313131', fontFamily: 'Futura-pt', textTransform: 'uppercase', fontSize: 16, letterSpacing: 2}}>Add new staff</h3>

          <input className='top-input' placeholder='Full name' type='text' value={name} onChange={(e) => setName(e.target.value)}/>
          <input className='bottom-input' placeholder='Email address' type='text' value={email} onChange={(e) => setEmail(e.target.value)}/>


          <button className='modal-btn'  disabled={loading} onClick={handleSubmit}>
            {loading ? 'Creating...' : "Create"}
          </button>
        </div>

    </div>
  )
}
